var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('Indicators',{attrs:{"indicatorsTotal":_vm.summary}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{ref:"pendingSettlement",staticClass:" mr-1",staticStyle:{"position":"relative"},attrs:{"variant":"outline-primary"},on:{"click":_vm.showPending}},[_c('span',[_vm._v("PENDING")]),(_vm.S_COUNTER_OFFERS_PENDING > 0)?_c('b-badge',{staticClass:"ml-1",staticStyle:{"position":"absolute","right":"-7px","top":"-8px"},attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.S_COUNTER_OFFERS_PENDING)+" ")]):_vm._e()],1),_c('b-button',{ref:"createSettlementButton",attrs:{"variant":"outline-success"},on:{"click":_vm.showCreateSettlement}},[_vm._v("CREATE")])],1)],1),_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{ name: 'debtsolution-settlements-to-be-approved' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" TO BE APPROVED "),_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.S_COUNTER_SETTLEMENTS.to_be_approved,"badge-classes":"badge-danger"}})],1)]),_c('b-nav-item',{attrs:{"to":{ name: 'debtsolution-settlements-to-pay' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" TO PAY "),(_vm.currentUser.role_id != 1)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.S_COUNTER_SETTLEMENTS.to_pay,"badge-classes":"badge-danger"}})],1):_vm._e()]),_c('b-nav-item',{attrs:{"to":{ name: 'debtsolution-settlements-finished' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[_vm.$route.matched[3].name == 'debtsolution-settlements-finished' ? 'router-link-active' : '' ,
                      'sub-tab-nav',
                      'px-3',
                      _vm.bgTabsNavs]}},[_vm._v(" FINISHED ")]),_c('b-nav-item',{attrs:{"to":{ name: 'debtsolution-settlements-unfinished' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" UNFINISHED "),(_vm.currentUser.role_id != 3)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.G_COUNTER_UNFINISHED <= 99 ? _vm.G_COUNTER_UNFINISHED : '99+',"badge-classes":"badge-danger"}})],1):_vm._e()]),_c('b-nav-item',{attrs:{"to":{ name: 'debtsolution-settlements-rejected' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" REJECTED ")])],1),(_vm.showPendingModal)?_c('SettlementOffersPendingModal',{on:{"closing":_vm.closeSettlemenOffersPendingtModal}}):_vm._e(),_c('b-card',{staticClass:"border-top-info border-3 border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"componentSettlements"})],1),(_vm.modalCreateSettlementOn)?_c('create-settlement-grid-modal',{on:{"close":function($event){_vm.modalCreateSettlementOn = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }